import { useGetCompanyInfo } from '/machinery/useGetCompanyInfo'

import { EnterAnimation } from '/features/buildingBlocks/EnterAnimation'
import { ContainerXl } from '/features/buildingBlocks/Container'
import { TagsWrapper } from '/features/buildingBlocks/TagsWrapper'
import { Tag, TagWithLogo } from '/features/buildingBlocks/Tag'
import { ClosingDateTag } from '/features/pageOnly/ClosingDateTag'
import { MediaWindow } from '/features/pageOnly/MediaWindow'

import styles from './HeroJobDetail.css'

export function HeroJobDetail({ title, heroImage, jobInfoTags, closedDate, company, layoutClassName = undefined }) {
  return (
    <section data-x='hero' className={cx(styles.component, layoutClassName)}>
      <ContainerXl>
        <div className={styles.container}>
          <Info layoutClassName={styles.infoLayout} {...{ title, jobInfoTags, closedDate, company }} />
          {heroImage && (
            <EnterAnimation offset={0} layoutClassName={styles.imageLayout}>
              <MediaWindow image={heroImage} aspectRatio={2 / 3} layoutClassName={styles.imageLayout} />
            </EnterAnimation>
          )}
        </div>
      </ContainerXl>
    </section>
  )
}

function Info({ title, jobInfoTags, closedDate, company, layoutClassName = undefined }) {
  const companyInfo = useGetCompanyInfo(company)

  return (
    <div className={cx(styles.componentInfo, layoutClassName)}>
      <EnterAnimation offset={0}>
        <h1 className={styles.jobTitle}>{title}</h1>
      </EnterAnimation>

      <EnterAnimation delay={250} offset={0}>
        <TagsWrapper>
          {companyInfo && <TagWithLogo colorScheme='white' icon={companyInfo.logo}  /> }
          {jobInfoTags?.map(({ label, colorScheme }, i) => label && <Tag key={i} {...{ label, colorScheme }} />)}
          {closedDate && <ClosingDateTag {...{ closedDate }} />}
        </TagsWrapper>
      </EnterAnimation>
    </div>
  )
}
