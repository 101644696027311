import { useRenderOnMount } from '@kaliber/use-render-on-mount'
import { useTranslate } from '/machinery/I18n'
import { unixTimeStampToFormattedDate } from '/machinery/dayjs'

import { TagWithIcon } from '/features/buildingBlocks/Tag'

import warningIcon from '/images/icons/warning.raw.svg'

export function ClosingDateTag({ closedDate, layoutClassName = undefined }) {
  const { __ } = useTranslate()
  const isMounted = useRenderOnMount()
  const closingDateFormatted = unixTimeStampToFormattedDate(closedDate)

  const timeDifference = closedDate * 1000 - new Date().getTime()
  const daysDifference = Math.floor(timeDifference / (24 * 60 * 60 * 1000))
  const closesSoon = daysDifference <= 7
  const closesTomorrow = daysDifference === 1
  const closesToday = daysDifference === 0
  const hasClosed = daysDifference < 0

  const labelText = hasClosed
    ? `${__`application-has-closed`} ${closingDateFormatted}`
    : closesTomorrow
      ? `${__`closes-in`} ${daysDifference} ${__`day`}`
      : closesToday
        ? `${__`closes-today`}`
        : closesSoon
          ? `${__`closes-in`} ${daysDifference} ${__`days`}`
          : `${__`closes-at`} ${closingDateFormatted}`

  return isMounted && <TagWithIcon label={labelText} icon={warningIcon} colorScheme='darkBlue' {...{ layoutClassName }} />
}
