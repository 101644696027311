import { ContainerMd } from '/features/buildingBlocks/Container'
import { PortableTextComponent } from '/features/buildingBlocks/PortableTextComponent'

import styles from './PortableTextWithTitle.css'

export function PortableTextWithTitle({ title, text }) {
  return (
    <div data-x='text-with-title'>
      <ContainerMd>
        <div className={styles.component}>
          <h2 className={styles.heading}>{title}</h2>
          <PortableTextComponent value={text} />
        </div>
      </ContainerMd>
    </div>
  )
}
