import { useMergeRefs } from '@floating-ui/react'
import { useScrollProgression, triggers } from '@kaliber/scroll-progression'

import { useTranslate } from '/machinery/I18n'
import { trackJobDetailView } from '/machinery/tracking/pushToDataLayer'

import { ApplyNowHeader } from '/features/pageOnly/JobDetail/ApplyNowHeader'
import { HeroJobDetail } from '/features/pageOnly/JobDetail/HeroJobDetail'
import { ContainerXl } from '/features/buildingBlocks/Container'
import { ContentWrapper } from '/features/buildingBlocks/ContentWrapper'
import { LargeIntroText } from '/features/pageOnly/LargeIntroText'
import { BlockWithTitleAndLine } from '/features/pageOnly/BlockWithTitleAndLine'
import { BlockWithImages } from '/features/pageOnly/BlockWithImages'
import { useNormalizedJobInfoTags } from '/features/pageOnly/useNormalizedJobInfoTags'
import { PortableTextWithTitle } from '/features/pageOnly/PortableTextWithTitle'
import { BenefitsBanner } from '/features/pageOnly/BenefitsBanner'
import { ApplyNow } from '/features/pageOnly/ApplyNow'
import { ContentSlider } from '/features/pageOnly/ContentSlider'
import { AskYourQuestionsToCustomContact, AskYourQuestionsToRecruiter } from '/features/pageOnly/AskYourQuestions'
import { ContentWithoutUniversalComponents } from '/features/buildingBlocks/ContentWithoutUniversalComponents'

import styles from './JobDetailApp.css'

export function JobDetailApp({ doc, applyLink, referLink = undefined }) {
  const { __ } = useTranslate()

  const {
    id,
    title,
    description,
    jobArea,
    closedDate,
    company,
    employmentType,
    hoursPerWeek,
    education,
    what,
    where,
    offer,
    information,
    profile,
    recruiter,
    hiringManager,
    experienceLevel,
    salaryScale,
    customContactInfo,
    jobAreaInfo,
    canBeReferred
  } = doc
  const { jobAreaDetail, jobAreaBenefits, jobAreaContentSlider } = jobAreaInfo || {}
  const { heroImage, content, whereImagesAndCTA } = jobAreaDetail || {}

  const jobInfoTags = useNormalizedJobInfoTags({ jobArea, employmentType, hoursPerWeek, education, experienceLevel, salaryScale, canBeReferred })

  const [scrollProgression, setScrollProgression] = React.useState(0)
  const ref = useScrollProgression({
    start: { element: triggers.top(), scrollParent: triggers.custom(1) },
    end: { element: triggers.bottom(), scrollParent: triggers.custom(1) },
    onChange(progression) { setScrollProgression(progression) }
  })

  const showBenefits = determineShowBenefits({ company, employmentType })

  const elementRef = React.useRef(null)
  const [contentScrollTop, setContentScrollTop] = React.useState(0)

  React.useEffect(
    () => {
      handleScroll()
      window.addEventListener('scroll', handleScroll)

      return () => window.removeEventListener('scroll', handleScroll)

      function handleScroll() {
        const { y } = elementRef.current.getBoundingClientRect()
        setContentScrollTop(y)
      }
    },
    []
  )

  React.useEffect(
    () => {
      trackJobDetailView(doc)
    },
    [doc]
  )

  return (
    <div className={styles.app}>
      <HeroJobDetail layoutClassName={styles.heroLayout} {...{ title, company, heroImage, jobInfoTags, closedDate }} />

      {(applyLink || referLink) && <ApplyNowHeader layoutClassName={styles.applyNowHeaderLayout} {...{ title, applyLink, referLink, contentScrollTop, scrollProgression }} />}

      <div ref={useMergeRefs([ref, elementRef])} className={styles.wrapper}>
        <ContentWrapper>
          {description && (
            <ContainerXl>
              <LargeIntroText value={description} />
            </ContainerXl>
          )}

          {content && <ContentWithoutUniversalComponents {...{ content }} />}

          {what && (
            <ContainerXl>
              <BlockWithTitleAndLine title={__`what-does-your-work-look-like`} text={what} />
            </ContainerXl>
          )}

          {where && (
            <ContainerXl>
              <BlockWithImages title={__`where-do-you-land`} text={where} cta={whereImagesAndCTA?.cta} images={whereImagesAndCTA?.images} />
            </ContainerXl>
          )}

          {profile && <PortableTextWithTitle title={__`your-profile`} text={profile} />}

          {Boolean(jobAreaBenefits?.benefits) && showBenefits && (
            <ContainerXl>
              <BenefitsBanner benefits={jobAreaBenefits.benefits} />
            </ContainerXl>
          )}

          {offer && <PortableTextWithTitle title={__`offer`} text={offer} />}

          {jobAreaContentSlider?.contentSlider?.items?.length && (
            <ContentSlider items={jobAreaContentSlider.contentSlider.items} />
          )}

          {information && <PortableTextWithTitle title={__`information`} text={information} />}

          <ContainerXl>
            <ApplyNow {...{ applyLink, referLink }} />
          </ContainerXl>
        </ContentWrapper>

      </div>
      {customContactInfo
        ? <AskYourQuestionsToCustomContact applicationId={id} {...{ customContactInfo }} />
        : recruiter
          ? <AskYourQuestionsToRecruiter applicationId={id} {...{ recruiter, hiringManager }} />
          : null
      }
    </div>
  )
}

function determineShowBenefits({ company, employmentType }) {
  return company?.id === 'klm' && employmentType?.id !== 'internship'
}
