import { useTranslate } from '/machinery/I18n'

import { EnterAnimation } from '/features/buildingBlocks/EnterAnimation'
import { ButtonLinkBlue } from '/features/buildingBlocks/Button'

import styles from './ApplyNow.css'

export function ApplyNow({ applyLink, referLink }) {
  const { __ } = useTranslate()

  return (
    <div data-x='apply-now' className={styles.component}>
      <EnterAnimation offset={-0.1}>
        <h3 className={styles.heading}>{applyLink ? __`apply-now` : __`want-to-apply`}</h3>
      </EnterAnimation>
      <ApplyNowCTA layoutClassName={styles.ctaLayout} {...{ applyLink, referLink }} />
    </div>
  )
}

function ApplyNowCTA({ applyLink, referLink, layoutClassName = undefined }) {
  const { __ } = useTranslate()

  return (
    <section data-x='apply-now' className={cx(styles.componentCTA, layoutClassName)}>
      {(applyLink || referLink)
        ? (
          <>
            <p className={styles.text}>
              {__`do-you-think`}
              <br />
              {__`start-your-journey`}
            </p>

            {referLink && (
              <ButtonLinkBlue
                href={referLink}
                label={__`refer-a-friend`}
                dataX='go-to-referer-form'
                layoutClassName={styles.buttonLayout}
              />
            )}

            {applyLink && (
              <ButtonLinkBlue
                href={applyLink}
                label={__`start-application`}
                dataX='go-to-application'
                layoutClassName={styles.buttonLayout}
              />
            )}
          </>
        ) : (
          <p className={styles.notAvailableText}>
            {__`come-back-later-external-vacancy`}
          </p>
        )
      }
    </section>
  )
}
