import { useRenderOnMount } from '@kaliber/use-render-on-mount'
import { useMediaQuery } from '@kaliber/use-media-query'
import { viewportMd, viewportLg } from '/cssGlobal/media.css'
import { useTranslate } from '/machinery/I18n'
import { useScrollDirection } from '/machinery/useScrollDirection'

import { ContainerXl } from '/features/buildingBlocks/Container'
import { ButtonLinkWhiteXs } from '/features/buildingBlocks/Button'

import styles from './ApplyNowHeader.css'


export function ApplyNowHeader({ title, applyLink, contentScrollTop, scrollProgression, referLink = undefined, layoutClassName = undefined }) {
  const isMounted = useRenderOnMount()
  const isViewportLg = useMediaQuery(viewportLg) ?? false
  const { isVisible, isVisibleWithHeader, isHidden } = useHeaderScolling({ contentScrollTop, scrollProgression })

  return (
    isMounted && (
      <header className={cx(styles.component, isVisible && styles.isVisible, isHidden && styles.isHidden, layoutClassName)}>
        {isViewportLg
          ? <Bar layoutClassName={styles.barLayout} {...{ title, applyLink, referLink, isVisibleWithHeader }} />
          : <ContainerXl><Bar layoutClassName={styles.barLayout} {...{ title, applyLink, referLink, isVisibleWithHeader }} /></ContainerXl>
        }
      </header>
    )
  )
}

function Bar({ title, applyLink, referLink, isVisibleWithHeader, layoutClassName = undefined }) {
  const isViewportMd = useMediaQuery(viewportMd) ?? false

  return (
    <div className={cx(styles.componentBar, isVisibleWithHeader && styles.isVisibleWithHeader, layoutClassName)}>
      {isViewportMd
        ? <ContainerXl><Content {...{ title, applyLink, referLink }} /></ContainerXl>
        : <Content {...{ title, applyLink, referLink }} />
      }
    </div>
  )
}

function Content({ title, applyLink, referLink }) {
  const { __ } = useTranslate()
  const isViewportMd = useMediaQuery(viewportMd) ?? false

  const hasOnlyOneLink = [applyLink, referLink].filter(Boolean).length === 1

  return (
    <div className={styles.componentContent}>
      <div className={styles.container}>
        {isViewportMd && <h3 className={styles.title}>{title}</h3>}

        <div className={cx(styles.buttonsContainer, hasOnlyOneLink && styles.hasOnlyOneLink)}>
          {referLink && <ButtonLinkWhiteXs label={__`refer-a-friend`} href={referLink} dataX='link-to-referer-form' layoutClassName={styles.buttonLayout} />}
          {referLink && applyLink && <span className={styles.seperator} />}
          {applyLink && <ButtonLinkWhiteXs label={__`apply-now`} href={applyLink} dataX='link-to-application-form' layoutClassName={styles.buttonLayout} />}
        </div>
      </div>
    </div>
  )
}

function useHeaderScolling({ contentScrollTop, scrollProgression }) {
  const { scrollDirection, metScrollThreshold } = useScrollDirection({ deltaThreshold: 50, scrollThreshold: 600 })
  const scrollingUp = scrollDirection === -1
  const headerIsVisible = scrollingUp && metScrollThreshold

  const isHidden = scrollProgression > 0.85
  const isVisible = metScrollThreshold
  const isVisibleWithHeader = (headerIsVisible && contentScrollTop < 80 && !isHidden)

  return { isVisible, isVisibleWithHeader, isHidden }
}
