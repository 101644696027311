import { useLanguage, useTranslate } from '/machinery/I18n'
import { routeMap } from '/routeMap'

import { ContainerXl } from '/features/buildingBlocks/Container'
import { EnterAnimation } from '/features/buildingBlocks/EnterAnimation'
import { HeadingLg } from '/features/buildingBlocks/Heading'
import { Icon } from '/features/buildingBlocks/Icon'

import styles from './AskYourQuestions.css'

import emailIcon from '/images/icons/mail.raw.svg'
import phoneIcon from '/images/icons/phone.raw.svg'
import faqIcon from '/images/icons/faq.raw.svg'

export function AskYourQuestionsToRecruiter({ recruiter, hiringManager = undefined, applicationId }) {
  return (
    <AskYourQuestionsBase>
      <Info layoutClassName={styles.infoLayout} {...{ recruiter, hiringManager, applicationId }}>
        <InfoTextRecruiter {...{ recruiter, hiringManager, applicationId }} />
      </Info>
      <ButtonsGridRecruiter layoutClassName={styles.buttonsLayout} {...{ recruiter, hiringManager }} />
    </AskYourQuestionsBase>
  )
}

export function AskYourQuestionsToCustomContact({ customContactInfo = undefined, applicationId }) {
  return (
    <AskYourQuestionsBase>
      <Info layoutClassName={styles.infoLayout} {...{ applicationId }}>
        <InfoTextCustomContact {...{ customContactInfo, applicationId }} />
      </Info>
      <ButtonsGridCustomContact layoutClassName={styles.buttonsLayout} {...{ customContactInfo }} />
    </AskYourQuestionsBase>
  )
}

function AskYourQuestionsBase({ children }) {
  return (
    <section data-x='ask-your-questions' className={styles.componentBase}>
      <ContainerXl>
        <div className={styles.container}>
          {children}
        </div>
      </ContainerXl>
    </section>
  )
}

function Info({ layoutClassName = undefined, children = undefined }) {
  const { __ } = useTranslate()

  return (
    <div className={cx(styles.componentInfo, layoutClassName)}>
      <EnterAnimation>
        <HeadingLg h='3'>{__`ask-your-questions`}</HeadingLg>
      </EnterAnimation>

      <EnterAnimation offset={-0.1} delay={50}>
        <p className={styles.infoText}>
          {children}
        </p>
      </EnterAnimation>
    </div>
  )
}

function InfoTextCustomContact({ customContactInfo, applicationId }) {
  const { __ } = useTranslate()
  const { name, email } = customContactInfo

  return (
    <>
      {__`if-you-have-any-questions-contact-department`}
      {name && <span className={styles.boldInfoText}> {name}</span>}
      {email && <span className={styles.idNumber}>{__`this-job-has-the-following-id`} {applicationId}</span>}
    </>
  )
}

function InfoTextRecruiter({ recruiter, hiringManager, applicationId }) {
  const { __ } = useTranslate()
  const { name: recruiterName } = recruiter || {}
  const { name: hiringManagerName } = hiringManager || {}
  const nameIsAvailable = recruiterName || hiringManagerName
  const hasBothContacts = recruiterName && hiringManagerName

  return (
    <>
      {nameIsAvailable ? __`if-you-have-any-questions-contact-our` : __`if-you-have-any-questions-take-a-look-at-our-faq`}
      {recruiterName && <span className={styles.boldInfoText}> {__`recruiter`} {recruiterName}</span>}
      {hasBothContacts && (` ${__`or`}`)}
      {hiringManagerName && <span className={styles.boldInfoText}> {__`hiring-manager`} {hiringManagerName}</span>}!
      {applicationId && <span className={styles.idNumber}>{__`this-job-has-the-following-id`} {applicationId}</span>}
    </>
  )
}

function ButtonsGridBase({ children, layoutClassName }) {
  return (
    <div className={cx(styles.componentButtonsGridBase, layoutClassName)}>
      {children}
    </div>
  )
}

function ButtonsGridCustomContact({ customContactInfo, layoutClassName = undefined }) {
  const { __ } = useTranslate()
  const language = useLanguage()
  const { name, phone, email } = customContactInfo || {}

  return (
    <ButtonsGridBase {...{ layoutClassName }}>
      <div className={styles.componentButtonsGridCustomContact}>
        {phone && (
          <AskYourQuestionsButton
            href={`tel:${phone}`}
            dataX='click-to-call-recruiter'
            type='phone'
            label={`${__`call`} ${name}`}
            icon={phoneIcon}
            ariaLabel='call recruiter button'
            layoutClassName={styles.buttonLayout}
            {...{ name }}
          />
        )}
        {email && (
          <AskYourQuestionsButton
            href={`mailto:${email}`}
            dataX='click-to-email-recruiter'
            type='email'
            label={`${__`email`} ${name}`}
            icon={emailIcon}
            ariaLabel='e-mail recruiter button'
            layoutClassName={styles.buttonLayout}
            {...{ name }}
          />
        )}
        <AskYourQuestionsButton
          href={routeMap.app.faq({ language })}
          dataX='click-to-faq-overview'
          type='link'
          label={__`see-faq`}
          icon={faqIcon}
          ariaLabel='go to FAQ button'
          layoutClassName={styles.buttonLayout}
        />
      </div>
    </ButtonsGridBase>
  )
}

function ButtonsGridRecruiter({ recruiter, hiringManager, layoutClassName = undefined }) {
  const { __ } = useTranslate()
  const language = useLanguage()
  const { name: recruiterName, phone: recruiterPhone, email: recruiterEmail } = recruiter || {}
  const { name: hiringManagerName, phone: hiringManagerPhone, email: hiringManagerEmail } = hiringManager || {}

  return (
    <ButtonsGridBase {...{ layoutClassName }}>
      <div className={styles.componentButtonsGridRecruiter}>
        {recruiterPhone && (
          <AskYourQuestionsButton
            href={`tel:${recruiterPhone}`}
            dataX='click-to-call-recruiter'
            type='phone'
            name={recruiterName}
            label={`${__`call`} ${recruiterName}`}
            icon={phoneIcon}
            ariaLabel='call recruiter button'
            layoutClassName={styles.buttonLayout}
          />
        )}
        {recruiterEmail && (
          <AskYourQuestionsButton
            href={`mailto:${recruiterEmail}`}
            dataX='click-to-email-recruiter'
            type='email'
            name={recruiterName}
            label={`${__`email`} ${recruiterName}`}
            icon={emailIcon}
            ariaLabel='e-mail recruiter button'
            layoutClassName={styles.buttonLayout}
          />
        )}
        {[hiringManagerPhone &&
        <AskYourQuestionsButton
          key='hiringManagerPhone'
          href={`tel:${hiringManagerPhone}`}
          dataX='click-to-call-hiring-manager'
          type='phone'
          name={hiringManagerName}
          label={`${__`call`} ${hiringManagerName}`}
          icon={phoneIcon}
          ariaLabel='call hiring manager button'
          layoutClassName={styles.buttonLayout}
        />,
        hiringManagerEmail &&
        <AskYourQuestionsButton
          key='hiringManagerEmail'
          href={`mailto:${hiringManagerEmail}`}
          dataX='click-to-email-hiring-manager'
          type='email'
          name={hiringManagerName}
          label={`${__`email`} ${hiringManagerName}`}
          icon={emailIcon}
          ariaLabel='e-mail hiring manager button'
          layoutClassName={styles.buttonLayout}
        />,
          <AskYourQuestionsButton
          key='faq'
          href={routeMap.app.faq({ language })}
          dataX='click-to-faq-overview'
          type='link'
          label={__`see-faq`}
          icon={faqIcon}
          ariaLabel='go to FAQ button'
          layoutClassName={styles.buttonLayout}
        />
        ].filter(Boolean).slice(0, 2)}
      </div>
    </ButtonsGridBase>
  )
}

function AskYourQuestionsButton({ href, dataX, type, label, icon, ariaLabel, name = undefined, layoutClassName = undefined }) {
  return (
    <button data-x={dataX} onClick={handleClick} className={cx(styles.componentButton, layoutClassName)}>
      <Icon layoutClassName={styles.iconLayout} {...{ icon }} />
      <span className={styles.buttonLabel}>{label}</span>
    </button>
  )

  function handleClick() {
    window.location.href = href
  }
}
